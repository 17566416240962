import { Dispatch, SetStateAction, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js';
import { useProfileQuery } from '@studio/hooks';
import { trackEvent } from '@studio/lib/heap';
import { Flex, Text, Toast } from '@lib/ui';
import { debounce } from '@lib/utils';
import * as EVENTS from '../../heap.constants';
import {
  useCreateStripeCheckoutSession,
  useCreateStripeCustomer,
} from '../../hooks';
import { Plan, PlanType } from '../../types';
import { ProgressBar } from '../progress-bar';
import { usePricingPlanABTest } from '../use-pricing-plan-ab-test';
import * as Styles from './choose-your-plan.css';
import { PricingPlanCard } from './pricing-plan-card';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY || '');

type ChooseYourPlanProps = {
  plan: Plan;
  selectedPlan: PlanType | undefined;
  className?: string;
  freeTrialEligible?: boolean;
  setSelectedPlan?: Dispatch<SetStateAction<PlanType | undefined>>;
};

export const ChooseYourPlan = ({
  plan,
  setSelectedPlan,
  selectedPlan,
  className,
}: ChooseYourPlanProps) => {
  const { t } = useTranslation();
  const { toast } = Toast.useToast();
  const { data, error: profileError } = useProfileQuery();
  const { createCustomer } = useCreateStripeCustomer();
  const { createSession } = useCreateStripeCheckoutSession();
  const [isProcessing, setIsProcessing] = useState(false);

  const { showTestFeature } = usePricingPlanABTest();

  if (profileError || !data) {
    console.error('Unable to retrieve profile data. Cannot create customer.');
  }

  const handleCreateCustomerAndSession = async (planType: PlanType) => {
    try {
      const primaryOrgUser = data?.organizationUsers.find(
        (orgUser) => orgUser.role === 'primary'
      );

      const orgId = primaryOrgUser?.organizationId;

      if (!orgId) {
        console.error('Organization ID is undefined. Cannot create customer.');
      }

      if (data && orgId) {
        const customer = await createCustomer({
          email: data.email,
          orgId,
          name: `${data.firstName || ''} ${data.lastName || ''}`,
        });

        if (customer) {
          const session = await createSession({
            planType,
            plan,
            customerId: customer.id,
            freeTrialEligible: data.freeTrialEligible,
          });
          return session;
        }
      }
    } catch (error) {
      console.error(
        'An error occurred during customer/session creation:',
        error
      );
      toast({
        message: t('Failed to subscribe. Please try again later.'),
      });
    }
  };

  const handleRedirect = debounce(
    async (planType: PlanType) => {
      if (isProcessing) return;

      setIsProcessing(true);

      const stripe = await stripePromise;

      try {
        const session = await handleCreateCustomerAndSession(planType);

        if (stripe && session) {
          trackEvent(EVENTS.SUBSCRIPTION_SIGNUP_START_TRIAL_CLICK);
          await stripe.redirectToCheckout({
            sessionId: session.sessionId,
          });
        }
      } catch (error) {
        console.error('An error occurred during the checkout process:', error);
        toast({
          message: t('Failed to subscribe. Please try again later.'),
        });
      } finally {
        setIsProcessing(false);
      }
    },
    1000,
    true
  );

  return (
    <Flex flexDirection="column" alignItems="center" className={className}>
      <ProgressBar currentStep={2} />
      <Text className={Styles.planTitle}>
        <Trans t={t}>Start your journey today</Trans>
      </Text>
      <Flex className={Styles.pricingPlanContainer}>
        {showTestFeature ? (
          <>
            <PricingPlanCard
              price={plan.price.annual}
              planType={PlanType.Yearly}
              selectedPlan={selectedPlan}
              discount={plan.discount}
              trial={data?.freeTrialEligible ? plan.trial : undefined}
              onClick={handleRedirect}
              setSelectedPlan={setSelectedPlan}
              isTestFeatureEnabled={showTestFeature}
            />
            <PricingPlanCard
              price={plan.price.monthly}
              planType={PlanType.Monthly}
              selectedPlan={selectedPlan}
              trial={data?.freeTrialEligible ? plan.trial : undefined}
              onClick={handleRedirect}
              setSelectedPlan={setSelectedPlan}
              isTestFeatureEnabled={showTestFeature}
            />
          </>
        ) : (
          <>
            <PricingPlanCard
              price={plan.price.monthly}
              planType={PlanType.Monthly}
              selectedPlan={selectedPlan}
              trial={data?.freeTrialEligible ? plan.trial : undefined}
              onClick={handleRedirect}
              setSelectedPlan={setSelectedPlan}
              isTestFeatureEnabled={showTestFeature}
            />
            <PricingPlanCard
              price={plan.price.annual}
              planType={PlanType.Yearly}
              selectedPlan={selectedPlan}
              discount={plan.discount}
              trial={data?.freeTrialEligible ? plan.trial : undefined}
              onClick={handleRedirect}
              setSelectedPlan={setSelectedPlan}
              isTestFeatureEnabled={showTestFeature}
            />
          </>
        )}
        <div className={Styles.focusLight} />
      </Flex>
    </Flex>
  );
};
