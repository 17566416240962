import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { trackEvent } from '@studio/lib/heap';
import { useParamStore } from '@studio/stores';
import { Button, Icons, Select } from '@lib/ui';
import { ACCESS, PARAM_KEYS } from '../../../constants';
import * as EVENTS from '../../../heap.constants';
import * as Styles from '../header.css';

const ScopeFilterItem = ({
  children,
  ...props
}: {
  children: string;
  value: string;
}) => {
  return (
    <Select.Item {...props}>
      <Select.ItemIndicator>
        <Icons.CheckmarkIcon aria-hidden />
      </Select.ItemIndicator>
      <Select.ItemText>{children}</Select.ItemText>
    </Select.Item>
  );
};

export function ScopeFilter() {
  const { t } = useTranslation();
  const { params, setParams } = useParamStore();

  const scopeValue = params[PARAM_KEYS.ACCESS] || ACCESS.ALL;
  const hasScope = scopeValue !== ACCESS.ALL;

  const items = useMemo(
    () => [
      {
        label: t('All'),
        value: ACCESS.ALL,
      },
      {
        label: t('Private'),
        value: ACCESS.USER_OWNED,
      },
      {
        label: t('Team'),
        value: ACCESS.SHARED,
      },
    ],
    [t]
  );

  const label = items.find((item) => item.value === scopeValue)?.label;

  const handleChange = (value: string) => {
    trackEvent(EVENTS.PROJECTS_FILTER_ACCESS_CHANGE, {
      old_value: scopeValue,
      new_value: value,
    });
    setParams({ [PARAM_KEYS.ACCESS]: value });
  };

  return (
    <Select.Root
      value={scopeValue}
      onValueChange={handleChange}
      onOpenChange={(value) => {
        if (value) {
          trackEvent(EVENTS.PROJECTS_FILTER_ACCESS_OPEN);
        }
      }}
    >
      <Select.Trigger label={items[0].label} asChild>
        <Button
          variant="subtle"
          size="sm"
          adornmentEnd={<Icons.ChevronDownIcon aria-hidden />}
        >
          {t('Access')}:{' '}
          <Select.Value placeholder={items[0].label}>
            <span className={clsx(hasScope && Styles.primaryText)}>
              {label}
            </span>
          </Select.Value>
        </Button>
      </Select.Trigger>
      <Select.Portal>
        <Select.Content>
          <Select.Viewport>
            <Select.Group>
              {items.map((item) => (
                <ScopeFilterItem
                  key={item.value}
                  children={t(item.label)}
                  {...item}
                />
              ))}
            </Select.Group>
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
}
