import { PropsWithChildren } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import useMediaQuery from '@studio/hooks/use-media-query';
import useScrollPosition from '@studio/hooks/use-scroll-position';
import { tokens } from '@lib/theme';
import { HorizontalLogo } from '@lib/ui';
import * as SignUpStyles from '../../routes/routes/new-signup.css';
import * as Styles from './layout.css';

export function Header(props: PropsWithChildren) {
  const { t } = useTranslation();
  const location = useLocation();
  const pathName = location.pathname;

  const isMobile = !useMediaQuery(tokens.breakpoints.sm);
  const isScrolled = useScrollPosition() > 0;

  const showLogin = pathName === '/auth/signup';

  return (
    <header
      className={`${Styles.header} ${
        isMobile && isScrolled ? Styles.headerScrolled : ''
      }`}
    >
      <HorizontalLogo className={SignUpStyles.logo} />
      {showLogin ? (
        <div className={Styles.actions}>
          <Trans t={t}>
            <span className={Styles.loginInstruction}>
              Already have an account?&nbsp;
            </span>
            <Link to="/home" className={Styles.loginLink}>
              <strong>Log in</strong>
            </Link>
          </Trans>
        </div>
      ) : (
        <div className={Styles.actions}>
          <Trans t={t}>
            <Link to="/auth/logout">
              <strong>Log out</strong>
            </Link>
          </Trans>
        </div>
      )}
    </header>
  );
}
