import { forwardRef, Ref } from 'react';
import * as ToggleGroup from '@radix-ui/react-toggle-group';
import clsx from 'clsx';
import * as Styles from './toggle-tabs.css';

export type ToggleTabsItemProps = ToggleGroup.ToggleGroupItemProps & {
  id: string;
  label?: string;
};

export type ToggleTabsProps = {
  className?: string;
  description?: string;
  onValueChange?: (value: string) => void;
  children?: React.ReactNode;
  value?: string;
};

type Props = ToggleTabsProps & Styles.ToggleTabsVariants;

const Root = forwardRef(
  (
    {
      className,
      description = 'Toggle option',
      variant = 'subtle',
      spacing = false,
      condensed = false,
      disabled = false,
      ...props
    }: Props,
    ref: Ref<HTMLDivElement> | undefined
  ) => {
    return (
      <ToggleGroup.Root
        ref={ref}
        type="single"
        className={clsx(
          Styles.toggleTabsVariants({
            disabled,
            condensed,
            spacing,
            variant,
          }),
          className
        )}
        aria-label={description}
        {...props}
      />
    );
  }
);

Root.displayName = 'ToggleTabsRoot';

const Item = forwardRef(
  (
    { className, children, id, label, value, ...props }: ToggleTabsItemProps,
    ref: Ref<HTMLButtonElement>
  ) => {
    return (
      <ToggleGroup.Item
        ref={ref}
        aria-label={label || value}
        className={clsx(Styles.item, className)}
        id={id}
        value={value}
        {...props}
        asChild
      >
        <button>{children}</button>
      </ToggleGroup.Item>
    );
  }
);

Item.displayName = 'ToggleTabsItem';

export { Root, Item };
