import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { FILTER_PARAMS } from '@studio/features/outliers/constants';
import { useParamStore } from '@studio/stores';
import { SuggestionChip, Select, Icons } from '@lib/ui';
import { PARAM_KEYS } from '../../../constants';
import * as EVENTS from '../../../heap.constants';

type optionProps = {
  text: string;
  shorten: string;
  value: string;
};

const options: optionProps[] = [
  {
    text: t('Hide kids content'),
    shorten: t('Hide'),
    value: 'false',
  },
  {
    text: t('Kids content only'),
    shorten: t('Only'),
    value: 'true',
  },
  {
    text: t('All content'),
    shorten: t('All'),
    value: 'both',
  },
];

export function KidsContentChip() {
  const { params, setParamsTracked } = useParamStore();
  const [selectedValue, setSelectedValue] = useState<string>(
    params[PARAM_KEYS.MADE_FOR_KIDS]
  );
  const [isOpen, setIsOpen] = useState(false);

  const events = {
    opened: EVENTS.OUTLIERS_FILTER_MADE4KIDS_OPEN,
    changed: EVENTS.OUTLIERS_FILTER_MADE4KIDS_CHANGE,
  };
  const paramId = FILTER_PARAMS.MADE_FOR_KIDS;

  useEffect(() => {
    setSelectedValue(params[PARAM_KEYS.MADE_FOR_KIDS]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params[PARAM_KEYS.MADE_FOR_KIDS]]);

  const handleChange = (value: string) => {
    setSelectedValue(value);
    setIsOpen(false);

    const selectedOption = options.find((option) => option.value === value);
    if (selectedOption) {
      setParamsTracked(events.changed, {
        [paramId]: selectedOption.value,
      });
    }
  };

  const SelectItem = ({ text, value }: optionProps) => (
    <Select.Item value={value}>
      <Select.ItemText>{text}</Select.ItemText>
      <Select.ItemIndicator>
        <Icons.CheckmarkIcon aria-hidden />
      </Select.ItemIndicator>
    </Select.Item>
  );

  const appliedLabel =
    options.find((option) => option.value === selectedValue)?.shorten || '';

  return (
    <Select.Root
      value={selectedValue}
      onValueChange={handleChange}
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      <Select.Trigger asChild>
        <SuggestionChip
          isRequired
          label={t('Kids content')}
          applied={Boolean(selectedValue)}
          appliedLabel={t(appliedLabel)}
          condensed
          onClick={() => setIsOpen(true)}
        />
      </Select.Trigger>
      <Select.Portal>
        <Select.Content align="start">
          <Select.Viewport>
            {options.map((option) => (
              <SelectItem key={option.value} {...option} />
            ))}
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
}
