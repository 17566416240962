import {
  ChangeEvent,
  forwardRef,
  Ref,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useGateValue } from '@statsig/react-bindings';
import { v4 as uuidv4 } from 'uuid';
import { useStore } from 'zustand';
import { promptOptions } from '@studio/features/ideation';
import {
  DEFAULT_PROJECT_CONCEPT_MAX_SIZE,
  PANEL_CONTROL_INPUTS,
} from '@studio/features/projects/constants';
import { useIdeationStore } from '@studio/features/projects/stores';
import { ProjectQueryParams } from '@studio/features/projects/types';
import { useBrainstorm, useCopyToClipboard } from '@studio/hooks';
import { ProjectStateType, ProjectStoreContext } from '@studio/providers';
import { useModalStore, useParamStore } from '@studio/stores';
import {
  EXIT_IDEATION,
  IDEATE_OPTION,
  IdeateOptionType,
  PACKAGE,
  PROJECT_ELEMENT,
} from '@lib/types';
import { Icons, TextArea, Toast, IdeationElement, MenuAction } from '@lib/ui';
import { STATSIG_FEATURE_FLAGS } from '@lib/utils';
import * as Styles from './project-elements.css';
import { useProjectPackageAnalytics } from './use-project-package-analytics';

export const ProjectConcept = forwardRef(
  (
    { onBrainstorm, disabled, ...props }: IdeationElement.IdeationElementProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const type = PROJECT_ELEMENT.CONCEPT;
    const { t } = useTranslation();
    const { setParams } = useParamStore<ProjectQueryParams>();
    const [copyToClipboard] = useCopyToClipboard();
    const { openModal } = useModalStore();
    const { closeIdeation, startIdeation, ideaPanes } = useIdeationStore();
    const { toast } = Toast.useToast();
    const showNewPanelLayout = useGateValue(
      STATSIG_FEATURE_FLAGS.PROJECT_DETAIL_PANEL_LAYOUT
    );
    const { projectStore } = useContext(ProjectStoreContext);

    const {
      updateElement,
      deleteElement,
      elementState,
      removeElementFromPrimary,
    } = useStore(projectStore, (state: ProjectStateType) => ({
      elementState: state[type],
      updateElement: state.updateElement,
      deleteElement: state.deleteElement,
      removeElementFromPrimary: state.removeElementFromPrimary,
    }));

    const [createMode, setCreateMode] = useState<boolean>(false);

    const ACTIONS = MenuAction.MENU_ACTION;

    const primaryContent =
      (elementState.options &&
        elementState.options.find((item) => item.id === elementState.primary)
          ?.content) ||
      '';

    const projectType = type === PROJECT_ELEMENT.CONCEPT ? 'hook' : type;

    const hasContent = primaryContent.trim().length > 0;

    const [value, setValue] = useState(primaryContent);

    const { startBrainstorm } = useBrainstorm(type);

    const conceptTextAreaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
      setValue(primaryContent);
    }, [primaryContent]);

    const onOpenSpider = () => {
      setParams({
        [ProjectQueryParams.PANEL_CONTROL]: PANEL_CONTROL_INPUTS,
        [ProjectQueryParams.ELEMENT]: type,
      });
    };

    const handleInitiateBrainstorm = () => {
      onOpenSpider();
      if (!hasContent) {
        if (onBrainstorm) {
          onBrainstorm({});
        }
        startIdeation();
        startBrainstorm({ input: { name: 'emptyState' } });
      }
    };

    const focusConceptTextArea = () => {
      if (conceptTextAreaRef.current) {
        conceptTextAreaRef.current.focus();
      }
    };

    const handleBlur = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const newValue = event.target.value;
      if (!newValue || newValue === value) {
        return;
      }
      updateElementState(newValue);
    };

    const updateElementState = (value: string) => {
      const uuid = createMode ? uuidv4() : elementState.primary || '';
      updateElement(type, value.trim(), uuid);
      setCreateMode(false);
    };

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      setValue(event.target.value);
      updateElementState(event.target.value);

      if (event.target.value.length >= DEFAULT_PROJECT_CONCEPT_MAX_SIZE) {
        toast({
          message: t('Hook has a max character limit of 750'),
        });
      }
    };

    const { onCreateElement, onDeleteElement, onCopyElement } =
      useProjectPackageAnalytics(projectStore.getState().id);

    const handleCreate = () => {
      setCreateMode(true);
      removeElementFromPrimary(type);
      onCreateElement(type);
    };

    const handleDelete = () => {
      if (elementState.primary) {
        deleteElement(type, elementState.primary);
        onDeleteElement(type);
      }
    };

    const handleCopy = () => {
      copyToClipboard(primaryContent);
      onCopyElement(type);
      toast({
        message: t('{{type}} copied!', {
          type: `${projectType.charAt(0).toUpperCase()}${projectType.slice(1)}`,
        }),
      });
    };

    const handleBrainstorm = (
      variant: IdeateOptionType,
      value?: Record<string, string>
    ) => {
      const properties = {
        [type]: projectStore.getState().getPrimary(type),
        ...value,
      };
      if (onBrainstorm) {
        onBrainstorm({
          variant,
          properties,
        });
      }
      startBrainstorm({
        input: {
          name: variant,
          value: properties,
        },
      });
    };

    const handleExitBrainstorm = () => {
      if (showNewPanelLayout) {
        return;
      }
      if (ideaPanes?.length > 0) {
        openModal(EXIT_IDEATION);
      } else {
        closeIdeation();
      }
    };

    const includedPrompts = [
      {
        value: IDEATE_OPTION.VARIATIONS,
        handler: () => handleBrainstorm(IDEATE_OPTION.VARIATIONS),
      },
      {
        value: IDEATE_OPTION.EXPLODE,
        handler: () => handleBrainstorm(IDEATE_OPTION.EXPLODE),
      },
      {
        value: IDEATE_OPTION.THIS_BUT,
        handler: (value?: Record<string, string>) =>
          handleBrainstorm(IDEATE_OPTION.THIS_BUT, value),
      },
    ];

    const ideationOptions = promptOptions(includedPrompts, type);

    const actions = [
      {
        type: ACTIONS.CREATE_NEW,
        label: t('Add new'),
        icon: <Icons.AddToProjectIcon aria-hidden />,
        handler: handleCreate,
      },
      {
        type: ACTIONS.COPY,
        label: t('Copy'),
        icon: <Icons.CopyIcon aria-hidden />,
        handler: handleCopy,
      },
      {
        type: ACTIONS.DELETE,
        label: t('Delete'),
        icon: <Icons.DeleteIcon aria-hidden />,
        handler: handleDelete,
      },
    ];

    return (
      <IdeationElement.Root id={type} ref={ref} {...props}>
        <div className={Styles.inputContainer}>
          <TextArea
            autoHeight
            resize="none"
            ref={conceptTextAreaRef}
            variant="dark"
            fill="solid"
            radii="xl"
            size="xl"
            className={`concept-input ${Styles.conceptInput} ${
              !value ? Styles.conceptInputExpand : ''
            }`}
            placeholder={t(`Add ${projectType}...`)}
            value={value}
            onBlur={handleBlur}
            onChange={handleChange}
            maxLength={DEFAULT_PROJECT_CONCEPT_MAX_SIZE}
            disabled={disabled}
          />
          {value && !disabled ? (
            <IdeationElement.Actions
              className={Styles.conceptActions}
              type={type}
              actions={actions}
              align="end"
              alignOffset={-1}
              side="bottom"
              variant="light"
              onMenuClose={focusConceptTextArea}
            />
          ) : null}
        </div>
        <IdeationElement.OptionsContainer>
          <IdeationElement.Options
            id={type}
            group={PACKAGE}
            noOptions={!hasContent}
            onBrainstorm={handleInitiateBrainstorm}
            onClose={handleExitBrainstorm}
            onOpen={onOpenSpider}
            options={ideationOptions}
          />
        </IdeationElement.OptionsContainer>
      </IdeationElement.Root>
    );
  }
);
