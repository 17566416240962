import { ComponentProps } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { components } from '@reactour/tour';
import clsx from 'clsx';
import { px, vars } from '@lib/theme';
import { Button } from '@lib/ui';
import { trackTourEvent } from '../events';
import { type Tours } from '../tours';
import { setTourAsSeen } from '../utils';
import { DisabledButton } from './styles.css';

export type NavigationProps = ComponentProps<typeof components.Navigation>;

type NavButtonProps = Pick<
  NavigationProps,
  'currentStep' | 'setCurrentStep'
> & {
  tourId: keyof Tours;
  kind: 'next' | 'prev';
  stepsLength: number;
  disabled?: boolean;
  setIsOpen?: (open: boolean) => void;
};

function NavButton({
  tourId,
  setCurrentStep,
  currentStep,
  kind,
  stepsLength,
  disabled,
  setIsOpen,
}: NavButtonProps) {
  const { t } = useTranslation();
  const isNext = kind === 'next';
  const isLast = isNext && currentStep === stepsLength - 1;
  let label = 'Back';
  if (isLast) {
    label = 'Finish';
  } else if (isNext) {
    label = 'Next';
  }

  return (
    <Button
      radii="pill"
      disabled={disabled}
      variant={isNext ? 'primary' : 'subtle'}
      className={clsx(disabled && DisabledButton)}
      onClick={() => {
        // extra safety but as it's setup now, Next and Back btns are not visible
        // when at end or start of tour, respectively, so *could* remove max() and min()
        if (isLast) {
          trackTourEvent('onboarding_tour_completed', tourId);
          setTourAsSeen(tourId);
          setIsOpen?.(false);
        } else if (isNext) {
          setCurrentStep(Math.min(currentStep + 1, stepsLength - 1));
        } else {
          setCurrentStep(Math.max(currentStep - 1, 0));
        }
      }}
    >
      <Trans t={t}>{label}</Trans>
    </Button>
  );
}

export function Navigation(props: NavigationProps & { tourId: keyof Tours }) {
  return (
    <components.Navigation
      {...props}
      // always disabled/hidden for now, designs keep shifting.
      prevButton={({ setCurrentStep, currentStep, stepsLength }) => (
        <NavButton
          disabled
          kind="prev"
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          stepsLength={stepsLength}
          setIsOpen={props.setIsOpen}
          tourId={props.tourId}
        />
      )}
      nextButton={({ setCurrentStep, currentStep, stepsLength }) => (
        <NavButton
          kind="next"
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          stepsLength={stepsLength}
          setIsOpen={props.setIsOpen}
          tourId={props.tourId}
        />
      )}
      styles={{
        dot: (base, state) => {
          return {
            ...base,
            transform: 'none',
            border: 'none',
            pointerEvents: 'none',
            margin: px(3),
            width: vars.scales.s08,
            height: vars.scales.s08,
            backgroundColor: state?.current
              ? vars.brand.light
              : vars.colors.gray650,
          };
        },
      }}
    />
  );
}
