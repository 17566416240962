import { Navigate, useLocation } from 'react-router-dom';
import { PricingPlansRoute, useAuthStore } from '@studio/features/auth';
import { useActiveChannelUcid } from '@studio/features/channel-select';
import { useOrganizationStore } from '@studio/stores';

function RedirectAuthenticatedRoot() {
  const activeChannelUcid = useActiveChannelUcid();
  const { activeOrganizationId } = useOrganizationStore();

  /**
   * Post login, the user should have an active organization and channel set,
   * let's push them over to that immediately.
   */

  if (activeOrganizationId && activeChannelUcid) {
    return (
      <Navigate
        to={`/app/${activeOrganizationId}/${activeChannelUcid}/dashboard`}
      />
    );
  }

  console.error('User reached unexpected redirect.');
  return <Navigate to="/no-channels" />;
}

export function RedirectRoot() {
  const { user } = useAuthStore();
  const { search: queryParams } = useLocation();

  if (!user) {
    return <Navigate to={`/home${queryParams}`} />;
  }

  const hasActiveSubscription = user.organizationUsers.some(
    (orgUser) =>
      orgUser.organization.subscriptionActive && orgUser.status === 'active'
  );

  if (hasActiveSubscription) {
    return <RedirectAuthenticatedRoot />;
  }

  return <PricingPlansRoute />;
}
