import { useRef, useState } from 'react';
import type { KeyboardEvent as ReactKeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { DISPLAY_FORMAT } from '@studio/features/outliers/constants';
import {
  Datepicker,
  Form,
  IconButton,
  Icons,
  Popover,
  TextInput,
} from '@lib/ui';
import * as Styles from './filter-date-input.css';

/**
 * TODO We need to also handle manually inputting a date
 */
export type DateInputProps = {
  /**
   * If true will close the popover once a date has been selected
   */
  closeOnSelection?: boolean;
  /**
   * Controls how the date should be formatted
   *
   * @example "MM/dd/yyyy"
   *
   * @default "M/dd/yyyy"
   */
  displayFormat?: string;
  /**
   * Error string to display
   */
  align?: 'start' | 'center' | 'end';
  /**
   * Class name
   */
  className?: string;
  /**
   * Error string to display
   */
  error?: string;
  /**
   * Id
   */
  id: string;
  /**
   * Label string to display
   */
  label?: string;
  /**
   * Input value
   */
  value?: Date;
  /**
   * mininum date in Calendar
   */
  minDate?: Date;
  /**
   * maximum date in Calendar
   */
  maxDate?: Date;
  /**
   * disable calendar date selection
   */
  disabled?: boolean;
  /**
   * Callback handler when the user hits the clear button
   */
  onClear?: () => void;
  /**
   * Callback handler when the user closes the date picker
   */
  onClose?: () => void;
  /**
   * Callback handler when the user opens the date picker
   */
  onOpen?: () => void;
  /**
   * Callback handler when the user selects a date
   */
  onSelectDate?: (value: Date) => void;
};

export function FilterDateInput(props: DateInputProps) {
  const {
    className,
    closeOnSelection,
    label,
    error,
    id,
    align = 'start',
    value,
    minDate,
    maxDate,
    displayFormat = DISPLAY_FORMAT,
    disabled,
    onClear,
    onClose,
    onOpen,
    onSelectDate,
  } = props;
  const { t } = useTranslation();

  const container = useRef<HTMLDivElement>(null);

  const [showDatePicker, setShowDatePicker] = useState(false);

  const handleOpenDatePicker = () => {
    if (!disabled) {
      setShowDatePicker(true);
    }
  };

  const handleEnterKey = (e: ReactKeyboardEvent) => {
    if (e.key === 'Enter') {
      handleOpenDatePicker();
    }
  };

  const handleChange = (value: string | Date) => {
    const formattedValue = format(value, displayFormat);
    if (onSelectDate) {
      onSelectDate(new Date(formattedValue));
    }
    if (closeOnSelection) {
      setShowDatePicker(false);
    }
  };

  const handleClear = () => {
    if (onClear) {
      onClear();
    }
  };

  const handleClose = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setShowDatePicker(false);
      if (onClose) {
        onClose();
      }
    }
  };

  const handleOpenChange = () => {
    if (onOpen) {
      onOpen();
    }
  };

  const handleClickOutside = (event: Event) => {
    const targetId = (event.target as HTMLElement).id;
    if (targetId === id) {
      return;
    }
    setShowDatePicker(false);
  };

  return (
    <Form.Field name={id} ref={container as React.RefObject<HTMLDivElement>}>
      <Form.Control asChild>
        <TextInput.Root
          size="sm"
          variant="dark"
          fill="sheer"
          hasError={!!error}
        >
          {label ? <TextInput.Label id={id}>{label}</TextInput.Label> : null}
          <TextInput.Input
            id={id}
            name={id}
            placeholder="mm/dd/yyyy"
            value={value ? format(value, displayFormat) : ''}
            onChange={handleChange}
            onClick={handleOpenDatePicker}
            onKeyDown={handleEnterKey}
            readOnly // Disallowing manual edit, requires major refactor
          >
            {value && !disabled ? (
              <TextInput.Adornment align="end">
                <IconButton
                  iconSize="16"
                  label={t('Clear input')}
                  icon={<Icons.CloseIcon aria-hidden />}
                  size="xs"
                  fill="none"
                  onClick={handleClear}
                  variant="subtle"
                />
              </TextInput.Adornment>
            ) : null}
          </TextInput.Input>
          <Popover.Root open={showDatePicker} onOpenChange={handleOpenChange}>
            <Popover.Trigger aria-hidden className={Styles.trigger} />
            <Popover.Portal container={container.current}>
              <Popover.Content
                align={align}
                sideOffset={0}
                onEscapeKeyDown={handleClose}
                onPointerDownOutside={handleClickOutside}
                className={`${Styles.content} ${className}`}
              >
                <Popover.Arrow className={Styles.arrow} />
                <Datepicker
                  onChange={handleChange}
                  minDate={minDate}
                  maxDate={maxDate}
                  value={value as Date}
                  disabled={disabled}
                />
              </Popover.Content>
            </Popover.Portal>
          </Popover.Root>
        </TextInput.Root>
      </Form.Control>
      {error ? <Form.Error>{error}</Form.Error> : null}
    </Form.Field>
  );
}
