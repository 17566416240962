import { useTranslation } from 'react-i18next';
import { DateFilter } from '@studio/components';
import { useParamStore } from '@studio/stores';
import { Form } from '@lib/ui';
import { DISPLAY_FORMAT, PARAM_KEYS } from '../../../constants';
import * as EVENTS from '../../../heap.constants';

export function DateRangeFilterInput() {
  const { params, removeParamTracked, setParamsTracked } = useParamStore();
  const { t } = useTranslation();

  const setAndTrackParams = (value: { [key: string]: string }) => {
    setParamsTracked(EVENTS.OUTLIERS_FILTER_UPLOADED_CHANGE, value);
  };

  const removeAndTrackParams = (value: string) => {
    removeParamTracked(EVENTS.OUTLIERS_FILTER_UPLOADED_CHANGE, value);
  };

  return (
    <Form.Root>
      <DateFilter
        minId={PARAM_KEYS.UPLOADED_START}
        minLabel={t('Start')}
        maxId={PARAM_KEYS.UPLOADED_END}
        maxLabel={t('End')}
        displayFormat={DISPLAY_FORMAT}
        params={params}
        setParams={setAndTrackParams}
        removeParam={removeAndTrackParams}
      />
    </Form.Root>
  );
}
