import { useFeatureGate } from '@statsig/react-bindings';
import { STATSIG_FEATURE_FLAGS } from '@lib/utils';
import { AugmentedStepType } from '../components';
import { getContentComponent, makeSelector } from '../utils';
import { TOUR_IDS } from './constants';

const getPropertiesStep = (isPanelLayoutSwapEnabled: boolean) => {
  const step: Partial<AugmentedStepType> = {
    type: 'step',
  };

  step.selector = makeSelector(
    isPanelLayoutSwapEnabled
      ? TOUR_IDS.PROJECT_DETAILS_PANEL
      : TOUR_IDS.PROJECT_PROPERTIES_PANEL
  );

  step.onBefore = () => {
    if (!isPanelLayoutSwapEnabled) {
      return;
    }

    const detailsItem = document.querySelector(
      `${makeSelector(TOUR_IDS.PROJECT_DETAILS_ITEM)} button`
    ) as HTMLButtonElement;
    detailsItem?.click();
  };

  step.body = isPanelLayoutSwapEnabled
    ? 'Keep your workflow organized with the <strong>Details Panel</strong>. Set publish dates, update your project status, and track your progress to stay on schedule.'
    : 'Keep your workflow organized with the <strong>Properties Panel</strong>. Set publish dates, update your project status, and track your progress to stay on schedule.';

  step.delay = isPanelLayoutSwapEnabled ? 350 : 0;

  return step as AugmentedStepType;
};

export const useProjectsFTUESteps = (): AugmentedStepType[] => {
  const { value: isPanelLayoutSwapEnabled } = useFeatureGate(
    STATSIG_FEATURE_FLAGS.PROJECT_DETAIL_PANEL_LAYOUT
  );

  const stepsSansContent: Omit<AugmentedStepType, 'content'>[] = [
    {
      selector: '#root',
      title: 'Welcome to Your Projects',
      body: 'This is where all your creative work comes together. Use Projects to organize your ideas, manage ongoing projects, and revisit past videos to inspire new ones.',
      type: 'takeover',
      videoSrc: '/assets/ftue-projects.mp4',
    },
    {
      selector: makeSelector(TOUR_IDS.PROJECTS_TOGGLE),
      body: 'Switch between <strong>In Progress</strong> and <strong>Published</strong> projects using the toggle at the top. We&apos;ve added your previously published videos as projects, making it easy to improve underperforming content and brainstorm sequels to your overperformers. This personalized setup helps you leverage past projects for future success.',
      type: 'step',
    },
    {
      onBefore() {
        const publishedToggleButton = document.querySelector(
          makeSelector(TOUR_IDS.PROJECTS_TOGGLE_PUBLISHED)
        ) as HTMLButtonElement;
        publishedToggleButton?.click();
      },
      selector: makeSelector(TOUR_IDS.NEW_PROJECT),
      body: "Click <strong>New Project</strong> to kick off your next video idea. Whether you're starting from scratch or building on an existing idea, Projects can help you stay organized.",
      type: 'step',
      delay: 350,
    },
    {
      onBefore() {
        const newProjectButton = document.querySelector(
          makeSelector(TOUR_IDS.NEW_PROJECT)
        ) as HTMLButtonElement;
        newProjectButton?.click();
      },
      selector: makeSelector(TOUR_IDS.PROJECT_PACKAGE),
      body: (
        <>
          Spotter Studio lets you start a project in different ways.
          <ul className="tour-list">
            <li>
              <strong>Starting from scratch?</strong> Click{' '}
              <strong>Brainstorm</strong> on any element to generate ideas
              without any input.
            </li>
            <li>
              <strong>Need to build on part of an idea?</strong> Input a title,
              hook, and/or thumbnail and use <strong>Brainstorm</strong> on
              another element to develop it further.
            </li>
            <li>
              <strong>Fully formed idea?</strong>Type in your title, hook, and
              thumbnail to organize and manage your project efficiently.
            </li>
          </ul>
        </>
      ),
      type: 'step',
      position: 'left',
      delay: 250,
      padding: {
        mask: [16, 120, 16, 16],
        popover: [16, 116, 16, 120],
      },
    },
    getPropertiesStep(isPanelLayoutSwapEnabled),
    {
      selector: '#root',
      type: 'takeover',
      body: 'Congratulations on completing the tour! Watch the Projects Feature Guide for additional tips and best practices. Return to your Projects anytime to build, refine, and manage your videos effectively.',
      videoSrc: 'https://player.vimeo.com/video/1017212134?h=3f76c567e0',
    },
  ];

  const steps: AugmentedStepType[] = stepsSansContent.map((step) => {
    const Content = getContentComponent(step);

    return {
      ...step,
      content: () => <Content {...step} />,
      navDotAriaLabel: '',
    };
  });

  return steps;
};
