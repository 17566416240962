import { api } from '@studio/lib';
import { OutlierVideo, OutlierVideosResponse } from '@lib/types';
import {
  transformObjectKeys,
  snakeCaseToCamelCase,
  camelCaseToSnakeCase,
} from '../helpers';

/**
 * 👋 Hello future developer
 * If you're wondering we need to transform the data in this function
 * it's because the DE API sends and receives data whose keys
 * are snake_cased. Our product API's require this data be camelCase.
 */
export const listVideos = async ({
  pageParam = 1,
  videoType = 'videos',
  ...rest
}) => {
  const input = { ...rest.params, page: pageParam.toString() };
  const transformedInput = transformObjectKeys(input, camelCaseToSnakeCase);

  const videoTypeSources: Record<string, string> = {
    videos: '/api/idea/channel_video',
    shorts: '/api/idea/shorts',
  };

  const videos = await api.dataApi.get<OutlierVideosResponse>(
    videoTypeSources[videoType],
    transformedInput
  );

  const transformedData = {
    ...videos,
    results: videos.results.map((result) => {
      return {
        ...transformObjectKeys(result, snakeCaseToCamelCase),
        // DE API returns the id as a number, product API's require it to be a string
        id: result.id.toString(),
      } as OutlierVideo;
    }),
  };

  return transformedData;
};

export default { listVideos };
