import { lazy } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ErrorBoundaryScreen } from '@studio/components';
import { authRoutes } from '@studio/features/auth/routes';
import { NoChannels } from '@studio/features/auth/routes/routes';
import { calendarRoutes } from '@studio/features/calendar/routes';
import { dashboardRoutes } from '@studio/features/dashboard/routes';
import { freeformIdeationRoutes } from '@studio/features/freeform-ideation/routes';
import { ideaWallRoutes } from '@studio/features/idea-wall/routes';
import { onboardingRoutes } from '@studio/features/onboarding/routes';
import { outlierRoutes } from '@studio/features/outliers/routes';
import { projectsRoutes } from '@studio/features/projects/routes';
import { settingsRoutes } from '@studio/features/settings';
import { RedirectRoot } from './redirect-root';

const AppLayout = lazy(
  () => import('@studio/features/app-shell/components/app-layout')
);

const routes = [
  /**
   * Root
   */
  {
    path: '/',
    element: <RedirectRoot />,
  },

  /**
   * Auth routes (including /home)
   */
  ...authRoutes,

  /**
   * Onboarding routes
   */
  ...onboardingRoutes,

  /**
   * Authenticated App routes
   */
  {
    path: '/app',
    element: <AppLayout />,
    errorElement: <ErrorBoundaryScreen />,
    children: [
      /**
       * Redirect base route to the appropriate destination
       */
      {
        path: '',
        element: <RedirectRoot />,
      },
      /**
       * Calendar
       */
      ...calendarRoutes,

      /**
       * Dashboard
       */
      ...dashboardRoutes,

      /**
       * Freeform Ideation
       */
      ...freeformIdeationRoutes,

      /**
       * Idea Wall
       */
      ...ideaWallRoutes,

      /**
       * Project listings and project details
       */
      ...projectsRoutes,

      /**
       * Outliers
       */
      ...outlierRoutes,

      /**
       * Settings routes are defined in their own routes file.
       */
      ...settingsRoutes,

      /*
       * No Channels Route (logged in)
       */
      {
        path: 'auth/no-channels',
        element: <NoChannels />,
      },
    ],
  },

  /**
   * No Channels Route (logged out)
   * TODO: Test if we need this route any longer
   */
  {
    path: '/auth/no-channels',
    element: <NoChannels />,
  },

  /**
   * Catch all - 404
   */
  {
    path: '*',
    async lazy() {
      const { NotFound } = await import('./screens/not-found');
      return {
        Component: NotFound,
      };
    },
  },
];

export const Router = () => {
  const router = createBrowserRouter(routes);
  return <RouterProvider router={router} />;
};
