import { t } from 'i18next';

export type optionProps = {
  text: string;
  value: string;
  min: string;
  max: string;
};

export const subscribersOptions: optionProps[] = [
  {
    text: t('Max 10K'),
    value: 'max_10k',
    min: '',
    max: '10000',
  },
  {
    text: t('Max 100K'),
    value: 'max_100k',
    min: '',
    max: '100000',
  },
  {
    text: t('Max 1M'),
    value: 'max_1m',
    min: '',
    max: '1000000',
  },
  {
    text: t('1M +'),
    value: 'min_1m',
    min: '1000000',
    max: '',
  },
];

export const viewsOptions: optionProps[] = [
  {
    text: t('1~10K'),
    value: 'max_10k',
    min: '',
    max: '10000',
  },
  {
    text: t('10k~100k'),
    value: '10k_to_100k',
    min: '10000',
    max: '100000',
  },
  {
    text: t('100k~500k'),
    value: '100k_to_500k',
    min: '100000',
    max: '500000',
  },
  {
    text: t('500k~1M'),
    value: '500k_to_1m',
    min: '500000',
    max: '1000000',
  },
  {
    text: t('1M +'),
    value: 'min_1m',
    min: '1000000',
    max: '',
  },
];

export const durationOptions: optionProps[] = [
  {
    text: t('Under 4 minutes'),
    value: 'max_4mins',
    min: '',
    max: '400',
  },
  {
    text: t('4~20 minutes'),
    value: '4_to_20mins',
    min: '400',
    max: '2000',
  },
  {
    text: t('Over 20 minutes'),
    value: 'min_20mins',
    min: '2000',
    max: '',
  },
];
