import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function AddIcon({ className, ...props }: IconProps) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className={clsx(iconStyles, className)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6364 2.63636C17.6364 1.73262 16.9037 1 16 1C15.0963 1 14.3636 1.73263 14.3636 2.63636V14.3636H2.63636C1.73262 14.3636 1 15.0963 1 16C1 16.9037 1.73262 17.6364 2.63636 17.6364H14.3636V29.3636C14.3636 30.2674 15.0963 31 16 31C16.9037 31 17.6364 30.2674 17.6364 29.3636V17.6364H29.3636C30.2674 17.6364 31 16.9037 31 16C31 15.0963 30.2674 14.3636 29.3636 14.3636H17.6364V2.63636Z"
        fill="currentColor"
      />
    </svg>
  );
}
