import { useFetchChannelTags } from '@studio/features/projects/hooks';
import { Tag } from '@studio/features/projects/types';
import { trackEvent } from '@studio/lib/heap';
import { useParamStore } from '@studio/stores';
import { PARAM_KEYS } from '../../../../constants';
import * as EVENTS from '../../../../heap.constants';
import { Tags } from '../../../properties';

export function TagsFilterInput() {
  const { params, setParams, removeParam } = useParamStore();
  const { data: channelTags } = useFetchChannelTags();

  const existingChannelTags =
    channelTags?.pages.flatMap((page) => page.results) || [];

  const paramTags = params[PARAM_KEYS.VIDEO_PROJECT_TAGS]
    ? decodeURIComponent(params[PARAM_KEYS.VIDEO_PROJECT_TAGS]).split(',')
    : [];

  const handleAddTag = (label: string) => {
    const matchingTag = existingChannelTags.find(
      (tag) => tag.content.toLowerCase() === label.toLowerCase()
    );

    if (matchingTag && !paramTags.includes(matchingTag.content)) {
      const updatedTags = [...paramTags, matchingTag.content];
      setParams({
        [PARAM_KEYS.VIDEO_PROJECT_TAGS]: encodeURIComponent(
          updatedTags.join(',')
        ),
      });
      trackTags(updatedTags);
    }
  };

  const handleRemoveTag = (tagToRemove: Tag) => {
    const updatedTags = paramTags.filter((tag) => {
      const existingTag = existingChannelTags.find((t) => t.content === tag);
      return existingTag?.id !== tagToRemove.id;
    });

    if (updatedTags.length > 0) {
      setParams({
        [PARAM_KEYS.VIDEO_PROJECT_TAGS]: encodeURIComponent(
          updatedTags.join(',')
        ),
      });
    } else {
      removeParam(PARAM_KEYS.VIDEO_PROJECT_TAGS);
    }

    trackTags(updatedTags);
  };

  const trackTags = (tags: string[]) => {
    const tagsLabels = tags.join(', ');
    trackEvent(EVENTS.PROJECTS_PROJECTS_TAGS_FILTER_CHANGE, {
      tags: tagsLabels,
    });
  };

  const selectedTags = paramTags
    .map((tagContent) =>
      existingChannelTags.find((tag) => tag.content === tagContent)
    )
    .filter((tag): tag is Tag => !!tag);

  return (
    <Tags
      onAddTag={handleAddTag}
      onRemoveTag={handleRemoveTag}
      selectedTags={selectedTags}
    />
  );
}
