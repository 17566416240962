import { FC, useRef, type PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import { BrainstormTourProvider } from './brainstorm-tour';
import { OutliersTourProvider } from './outliers-tour/provider';
import { ProjectsTourProvider } from './projects-tour/provider';
import { type Tours } from './tours';
import { hasUserSeenTour } from './utils';

type ConditionalTour = {
  condition: boolean;
  provider: FC<PropsWithChildren>;
  id: keyof Tours;
};

/**
 * TourProviderWrapper selects the appropriate tour provider, if any
 */
export const TourProviderWrapper = ({ children }: PropsWithChildren) => {
  const currentTour = useRef<string | undefined>(undefined);
  const location = useLocation();

  const selected = getProviderByPriority(
    location.pathname,
    currentTour.current
  );

  if (!selected?.Provider) {
    currentTour.current = undefined;
    return <>{children}</>;
  }

  const { Provider, id } = selected;
  currentTour.current = id;

  return <Provider>{children}</Provider>;
};

const shouldUseTour = (
  hasSeen: boolean,
  pathname: string,
  validPaths: string[],
  /**
   * provide this only to disambiguate between paths that may incidentally match
   * for ex.: '/projects' (valid) and '/projects/videoProject' (invalid)
   */
  invalidPaths?: string[]
) => {
  const should = !hasSeen && validPaths.some((path) => pathname.includes(path));

  if (should && invalidPaths) {
    return !invalidPaths.some((path) => pathname.includes(path));
  }

  return should;
};

/**
 * TODO: refactor tour selection generally to be less imperative and string-matchy
 */
const getProviderByPriority = (pathname: string, currentTour?: string) => {
  // first tour that meets its conditions gets shown
  const byPriority: ConditionalTour[] = [
    {
      // prettier-ignore
      condition: shouldUseTour(hasUserSeenTour('brainstorm'), pathname, ['/dashboard', '/projects/new-project']),
      provider: BrainstormTourProvider,
      id: 'brainstorm',
    },
    {
      // prettier-ignore
      condition: shouldUseTour(hasUserSeenTour('projects'), pathname, ['/projects'], ['/projects/videoProject']),
      provider: ProjectsTourProvider,
      id: 'projects',
    },
    {
      // prettier-ignore
      condition: shouldUseTour(hasUserSeenTour('outliers'), pathname, ['/outliers']),
      provider: OutliersTourProvider,
      id: 'outliers',
    },
  ];

  let Provider: FC<PropsWithChildren> | undefined;
  let id: keyof Tours | undefined;

  for (const { condition, provider, id: providerId } of byPriority) {
    if (condition) {
      Provider = provider;
      id = providerId;

      // one caveat: brainstorm and projects tours both appear on the PDP.
      // if user is in the projects tour but hasn't yet seen the brainstorm tour,
      // its usual PDP prioritization would cause brainstorm to be selected. prevent that.
      if (providerId === 'brainstorm' && currentTour === 'projects') {
        continue;
      }

      break;
    }
  }

  return {
    Provider,
    id,
  };
};
