import { Key, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { clsx } from 'clsx';
import { trackEvent } from '@studio/lib/heap';
import { useParamStore } from '@studio/stores';
import { StatusItem } from '@lib/types';
import { Button, Icons, Select } from '@lib/ui';
import { PARAM_KEYS, STATUS } from '../../../constants';
import * as EVENTS from '../../../heap.constants';
import * as Styles from '../header.css';

const StatusFilterItem = ({
  children,
  ...props
}: {
  children: string;
  value: string;
}) => {
  return (
    <Select.Item {...props}>
      <Select.ItemText>{children}</Select.ItemText>
      <Select.ItemIndicator>
        <Icons.CheckmarkIcon aria-hidden />
      </Select.ItemIndicator>
    </Select.Item>
  );
};

type Props = {
  items: StatusItem[];
};

//
// TODO: to support multiselect checkbox items, need to rewrite this component
//       using <DropdownMenu />
//
export function StatusFilter({ items }: Props) {
  const { t } = useTranslation();
  const { params, setParams, removeParam } = useParamStore();

  const itemsWithDefault = useMemo(
    () => [
      {
        label: t('All'),
        value: STATUS.ALL,
      },
      ...items,
    ],
    []
  );

  const statusValue = params[PARAM_KEYS.STATUS_IN] || STATUS.ALL;
  const hasStatus = statusValue !== STATUS.ALL;
  const label = itemsWithDefault.find((item) => item.value === statusValue)
    ?.label;

  const handleOnValueChange = (value: string) => {
    trackEvent(EVENTS.PROJECTS_PROJECTS_STATUS_FILTER_CHANGE, {
      old_value: statusValue,
      new_value: value,
    });

    if (value === STATUS.ALL) {
      removeParam(PARAM_KEYS.STATUS_IN);
    } else {
      setParams({ [PARAM_KEYS.STATUS_IN]: value });
    }
  };

  return (
    <Select.Root
      value={statusValue}
      onValueChange={handleOnValueChange}
      onOpenChange={(value) => {
        if (value) {
          trackEvent(EVENTS.PROJECTS_PROJECTS_STATUS_FILTER_OPEN);
        }
      }}
    >
      <Select.Trigger label={t('All')} asChild>
        <Button
          variant="subtle"
          size="sm"
          adornmentEnd={<Icons.ChevronDownIcon aria-hidden />}
        >
          {t('Status')}:{' '}
          <Select.Value>
            <span className={clsx({ [Styles.primaryText]: hasStatus })}>
              {label}
            </span>
          </Select.Value>
        </Button>
      </Select.Trigger>
      <Select.Portal>
        <Select.Content>
          <Select.Viewport>
            <Select.Group>
              {itemsWithDefault.map((item) => (
                <StatusFilterItem
                  key={item.value as unknown as Key}
                  value={item.value as unknown as string}
                >
                  {t(item.label)}
                </StatusFilterItem>
              ))}
            </Select.Group>
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
}
