import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { IconButton, Icons, Separator } from '../..';
import * as Styles from './suggestion-chip.css';

/**
 * SuggestionChip
 */
export type SuggestionChipProps = Styles.SuggestionChipVariants & {
  applied?: boolean;
  appliedLabel?: string;
  appliedCount?: number;
  isRequired?: boolean;
  id?: string;
  label?: string;
  onClick?: () => void;
  onDismiss?: () => void;
};

export const SuggestionChip = forwardRef<HTMLDivElement, SuggestionChipProps>(
  (
    {
      applied = false,
      appliedLabel,
      appliedCount,
      condensed = false,
      disabled = false,
      isRequired = false, // If required, the dismiss button disappears on applied state
      onClick,
      onDismiss,
      label,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();

    const isSplitButton = applied && !isRequired;

    const handleIconButtonClick = () => {
      onDismiss?.();
    };

    return (
      <div
        aria-roledescription={isSplitButton ? t('split button)') : ''}
        ref={ref}
        className={clsx(
          Styles.suggestionChipVariants({
            condensed,
            disabled,
            applied,
          })
        )}
      >
        {isSplitButton ? (
          <IconButton
            label={t('Clear')}
            icon={<Icons.RemoveMinusIcon aria-hidden />}
            size="xs"
            fill="ghost"
            className={Styles.adornmentVariants({
              condensed,
            })}
            onClick={handleIconButtonClick}
          />
        ) : null}
        <button
          {...props}
          className={Styles.appliedButtonVariants({ condensed, isSplitButton })}
          onClick={onClick}
        >
          {!applied ? (
            <span
              className={Styles.addIconWrapperVariants({
                condensed,
              })}
            >
              <Icons.AddAsOptionIcon aria-hidden />
            </span>
          ) : null}
          {label ? (
            <span className={Styles.labelVariants({ condensed, applied })}>
              {label}
            </span>
          ) : null}
          {applied ? (
            <>
              {appliedLabel ? (
                <>
                  <Separator
                    direction="vertical"
                    className={Styles.separator}
                  />
                  <span className={Styles.appliedLabel}>{appliedLabel}</span>
                </>
              ) : null}
              {appliedCount ? (
                <span
                  className={Styles.labelCountVariants({
                    condensed,
                  })}
                >
                  {appliedCount}
                </span>
              ) : null}
              <div className={Styles.iconWrapperVariants({ condensed })}>
                <Icons.CaretCondensedDownIcon aria-hidden />
              </div>
            </>
          ) : null}
        </button>
      </div>
    );
  }
);

SuggestionChip.displayName = 'SuggestionChip';
