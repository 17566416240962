import { useExperiment } from '@statsig/react-bindings';
import { STATSIG_AB_TESTS } from '@lib/utils';

export const useButtermilkExperiment = () => {
  const { value } = useExperiment(
    STATSIG_AB_TESTS.SUSI_BUTTERMILK_BACKGROUND_TEST
  );

  const showLightTheme =
    document.referrer.includes(import.meta.env.VITE_MARKETING_URL) &&
    (value?.use_light_theme as boolean);

  return {
    showLightTheme,
  };
};
