import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { IconButton, Icons } from '../..';
import * as Styles from './input-chip.css';

/**
 * Input Chip
 */
export type InputChipProps = Styles.InputChipVariants & {
  adornmentStart?: ReactElement;
  className?: string;
  label: string;
  onClick?: () => void;
  onDismiss?: () => void;
  selected?: boolean;
};

export const InputChip = ({
  adornmentStart = undefined,
  className,
  condensed = false,
  fill = 'ghost',
  label,
  onClick,
  onDismiss,
  pill = false,
  selected,
}: InputChipProps) => {
  const { t } = useTranslation();

  return (
    <div
      role="option"
      aria-selected={selected}
      onClick={onClick}
      className={clsx(
        Styles.inputChipVariants({
          condensed,
          fill,
          pill,
        }),
        className
      )}
    >
      <span className={Styles.adornmentVariants({ fill })}>
        {adornmentStart}
      </span>
      <span className={Styles.labelVariants({ condensed, fill })}>{label}</span>
      {onDismiss && (
        <IconButton
          className={clsx(Styles.closeVariants({ condensed, pill }))}
          icon={<Icons.CloseIcon aria-hidden />}
          label={t('Remove')}
          iconSize={condensed ? '12' : '16'}
          size="xs"
          fill="none"
          onClick={onDismiss}
        />
      )}
    </div>
  );
};
