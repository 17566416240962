import { useInfiniteQuery } from '@tanstack/react-query';
import { useActiveChannelUcid } from '@studio/features/channel-select';
import { USE_QUERY_KEYS } from '@studio/hooks';
import { useActiveOrganizationId } from '@studio/stores';
import { fetchChannelTags } from '../requests';
import { ChannelTagsAPIResponse } from '../types';

export function useFetchChannelTags() {
  const channelUcid = useActiveChannelUcid();
  const orgId = useActiveOrganizationId();

  return useInfiniteQuery<ChannelTagsAPIResponse>({
    queryKey: [USE_QUERY_KEYS.FETCH_CHANNEL_TAGS, channelUcid],
    queryFn: ({ pageParam = 1 }) =>
      fetchChannelTags(pageParam as number, orgId, channelUcid),
    initialPageParam: 1,
    getNextPageParam: ({ page }) => page.next ?? null,
    getPreviousPageParam: ({ page }) => page.previous ?? null,
  });
}
