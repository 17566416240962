import { ReactNode } from 'react';
import { Icons } from '@lib/ui';

export const MENU_ACTION = {
  ADD_AS_BACKUP: 'add_as_backup',
  ADD_TO_EXISTING: 'add_to_existing',
  ADD_TO_IDEA_BANK: 'add_to_idea_bank',
  REMOVE_AS_BACKUP: 'remove_as_backup',
  COPY: 'copy',
  CREATE_NEW: 'create_new',
  DELETE: 'delete',
  DOWNLOAD: 'download',
  EDIT_TYPEOVER: 'edit_typeover',
  EXPAND_VIEW_FULL_SCREEN: 'expand_view_full_screen',
  MAGNIFY: 'magnify',
  REFINE: 'refine',
  SAVE: 'save',
  SELECT: 'select',
  SEND_TO_BRAINSTORM: 'send_to_brainstorm',
};
type MenuActionType = (typeof MENU_ACTION)[keyof typeof MENU_ACTION];

type ActionItem = {
  value: MenuActionType;
  label: string;
  icon?: ReactNode;
};

export const ACTION_ITEMS: ActionItem[] = [
  {
    value: MENU_ACTION.ADD_AS_BACKUP,
    label: 'Backup option',
    icon: <Icons.PlusIcon aria-hidden />,
  },
  {
    value: MENU_ACTION.ADD_TO_EXISTING,
    label: 'Add to existing',
    icon: <Icons.AddToExistingIcon aria-hidden />,
  },
  {
    value: MENU_ACTION.ADD_TO_IDEA_BANK,
    label: 'Add to Idea Bank',
    icon: <Icons.PlusIcon aria-hidden />,
  },
  {
    value: MENU_ACTION.COPY,
    label: 'Copy',
    icon: <Icons.CopyIcon aria-hidden />,
  },
  {
    value: MENU_ACTION.CREATE_NEW,
    label: 'Create new',
    icon: <Icons.AddToProjectIcon aria-hidden />,
  },
  {
    value: MENU_ACTION.DELETE,
    label: 'Delete',
    icon: <Icons.DeleteIcon aria-hidden />,
  },
  {
    value: MENU_ACTION.DOWNLOAD,
    label: 'Download',
    icon: <Icons.DownloadIcon aria-hidden />,
  },
  {
    value: MENU_ACTION.EDIT_TYPEOVER,
    label: 'Edit/Typeover',
    icon: <Icons.EditIcon aria-hidden />,
  },
  {
    value: MENU_ACTION.EXPAND_VIEW_FULL_SCREEN,
    label: 'Expand/View full screen',
    icon: <Icons.ExpandIcon aria-hidden />,
  },
  {
    value: MENU_ACTION.MAGNIFY,
    label: 'Magnify',
    icon: <Icons.MagnifyIcon aria-hidden />,
  },
  {
    value: MENU_ACTION.REFINE,
    label: 'Refine',
    icon: <Icons.RefineIcon aria-hidden />,
  },
  {
    value: MENU_ACTION.REMOVE_AS_BACKUP,
    label: 'Remove as option',
    icon: <Icons.MinusIcon aria-hidden />,
  },
  {
    value: MENU_ACTION.SAVE,
    label: 'Save',
    icon: <Icons.HeartIcon aria-hidden />,
  },
  {
    value: MENU_ACTION.SELECT,
    label: 'Select',
    icon: <Icons.CheckmarkIcon aria-hidden />,
  },
  {
    value: MENU_ACTION.SEND_TO_BRAINSTORM,
    label: 'Send to Brainstorm',
    icon: <Icons.AIIcon aria-hidden />,
  },
];
