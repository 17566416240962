import { lazy, ReactNode, Suspense } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { PersistentMessageBannerProvider } from '@studio/features/persistent-banner/providers';
import { BrainstormTour } from '@studio/features/tours/brainstorm-tour';
import { ProjectsTour } from '@studio/features/tours/projects-tour/tour';
import { TourProviderWrapper } from '@studio/features/tours/tour-provider-wrapper';
import useIsMobile from '@studio/hooks/use-is-mobile';
import { BirdoProvider } from '@studio/lib/birdo-client';
import { RouteProvider } from '@studio/providers';
import { getLocalStorage } from '@studio/utils';
import { MobileOverlay, Sidebar as SidebarMenu } from '@lib/ui';
import { useAuthStore } from '../../auth';
import { DISMISS_MOBILE_OVERLAY_KEY } from '../constants';
import * as Styles from './layout.css';
import { ValidChannelMatch } from './valid-channel-match';
import { ValidOrganizationMatch } from './valid-organization-match';

const ExitModal = lazy(
  () => import('@studio/features/projects/components/exit-modal/exit-modal')
);
const SavedItems = lazy(
  () => import('@studio/features/saved-items/saved-items')
);
const Sidebar = lazy(() => import('./sidebar'));
const TopBar = lazy(() => import('./topbar'));

export default function AppLayout({ children }: { children?: ReactNode }) {
  const { user } = useAuthStore();
  const { getItem, setItem } = getLocalStorage();
  const hasDismissedMobileOverlay = getItem(DISMISS_MOBILE_OVERLAY_KEY);
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  if (!user) {
    return <Navigate to="/auth/logout" />;
  }

  return (
    <RouteProvider>
      <Suspense>
        <ValidOrganizationMatch>
          <ValidChannelMatch>
            <TourProviderWrapper>
              <MobileOverlay
                onDismiss={() => setItem(DISMISS_MOBILE_OVERLAY_KEY, true)}
                open={isMobile && !hasDismissedMobileOverlay}
              />
              <SidebarMenu.Root navigate={navigate}>
                <div className={Styles.appLayout}>
                  <Sidebar />
                  <main className={Styles.contentContainer}>
                    <TopBar />
                    {children ? children : <Outlet />}
                  </main>
                  <SavedItems />
                  <ExitModal />
                  <BrainstormTour />
                  <ProjectsTour />
                </div>
              </SidebarMenu.Root>
              <BirdoProvider />
            </TourProviderWrapper>
          </ValidChannelMatch>
        </ValidOrganizationMatch>
        <PersistentMessageBannerProvider />
      </Suspense>
    </RouteProvider>
  );
}
