import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { useActiveChannelUcid } from '@studio/features/channel-select';
import * as OUTLIERS_EVENTS from '@studio/features/dashboard/constants/heap.constants';
import { useInspirationStore } from '@studio/features/ideation';
import {
  useIdeationStore,
  useNewProjectId,
} from '@studio/features/projects/stores';
import { ProjectQueryParams } from '@studio/features/projects/types';
import { useSavedItemsStore } from '@studio/features/saved-items/stores';
import { trackEvent } from '@studio/lib/heap';
import { useActiveOrganizationId, useModalStore } from '@studio/stores';
import {
  EXIT_IDEATION,
  OutlierVideo,
  SavedItem,
  SavedItemsType,
} from '@lib/types';
import { BrainstormButton as BrainStormUI, Tooltip } from '@lib/ui';

type BrainstormButtonProps = {
  className?: string;
  isHovered?: boolean;
  isInteractable?: boolean;
  showTooltip?: boolean;
  isTopic?: boolean;
  topic?: string;
  status?: string;
  element?: string;
};

type BrainstormButtonWithDataProps = BrainstormButtonProps &
  (
    | {
        data?: OutlierVideo;
        type?: SavedItemsType.OUTLIERS;
      }
    | {
        data?: SavedItem;
        type?: Exclude<SavedItemsType, SavedItemsType.OUTLIERS>;
      }
  );

export const StartBrainstormButton = forwardRef<
  HTMLButtonElement,
  BrainstormButtonWithDataProps
>(
  (
    {
      className,
      data,
      isHovered = false,
      isInteractable = true,
      showTooltip = false,
      isTopic = false,
      topic,
      type,
      status,
      element = 'title',
      ...rest
    },
    ref
  ) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const orgId = useActiveOrganizationId();
    const channelUcid = useActiveChannelUcid();
    const { toggleVisibility, visible } = useSavedItemsStore();
    const {
      setConcepts,
      setOutliers,
      setThumbnails,
      setTitles,
      setTopic,
      reset,
    } = useInspirationStore();
    const { inIdeation } = useIdeationStore();
    const { openModal } = useModalStore();
    const { getId } = useNewProjectId();

    const sendToBrainstorm = () => {
      reset();
      if (visible) {
        toggleVisibility();
      }
      if (!data && !isTopic) {
        return;
      }
      if (topic) {
        // TODO refactor to handle Topic setting more gracefully
        setTopic(topic);
      } else {
        switch (type) {
          case SavedItemsType.OUTLIERS:
            setOutliers([data] as OutlierVideo[], true);
            break;
          case SavedItemsType.CONCEPT:
            setConcepts([data] as SavedItem[], true);
            break;
          case SavedItemsType.THUMBNAIL:
            setThumbnails([data] as SavedItem[], true);
            break;
          case SavedItemsType.TITLE:
            setTitles([data] as SavedItem[], true);
            break;
          default:
            console.warn(`Unhandled type: ${type}`);
        }
      }

      trackEvent(OUTLIERS_EVENTS.HOME_OUTLIER_BRAINSTORM_CLICK, {
        keyword: topic ?? '',
        page: location.pathname,
        data: JSON.stringify(data),
      });
      navigate({
        pathname: `/app/${orgId}/${channelUcid}/projects/new-project`,
        search: createSearchParams({
          [ProjectQueryParams.ELEMENT]: element,
          [ProjectQueryParams.BRAINSTORM]: 'true',
          [ProjectQueryParams.NEW_PROJECT_ID]: `${getId()}`,
          ...(status && { status }),
        }).toString(),
      });
    };

    const handleClick = () => {
      if (inIdeation) {
        openModal(EXIT_IDEATION, sendToBrainstorm);
        return;
      }
      sendToBrainstorm();
    };

    return (
      <Tooltip.Provider>
        <Tooltip.Root delayDuration={100}>
          <Tooltip.Trigger asChild>
            <BrainStormUI
              className={className}
              isHovered={isHovered}
              isInteractable={isInteractable}
              onClick={handleClick}
              ref={ref}
              {...rest}
            >
              {t('Brainstorm')}
            </BrainStormUI>
          </Tooltip.Trigger>
          {showTooltip ? (
            <Tooltip.Portal>
              <Tooltip.Content side="bottom" sideOffset={6}>
                {t('Brainstorm from this video')}
              </Tooltip.Content>
            </Tooltip.Portal>
          ) : null}
        </Tooltip.Root>
      </Tooltip.Provider>
    );
  }
);
