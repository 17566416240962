import { KeyboardEventHandler, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetchChannelTags } from '@studio/features/projects/hooks';
import { useOnClickOutside } from '@studio/hooks';
import {
  Button,
  IconButton,
  Icons,
  InputChip,
  Popover,
  TextInput,
} from '@lib/ui';
import { TAGS_LABEL_MAX_LENGTH } from '../../constants';
import { Tag } from '../../types';
import { LoadMoreTags } from './load-more-tags';
import * as Styles from './tags.css';

export type TagResultProps = Tag & {
  isSelected?: boolean;
  onAddTag: () => void;
};

export const TagResult = (props: TagResultProps) => {
  const { isSelected } = props;

  return (
    <Button
      className={Styles.result}
      variant="subtle"
      fill="ghost"
      size="sm"
      disabled={isSelected}
      onClick={props.onAddTag}
    >
      {props.content}
    </Button>
  );
};

interface TagsProps {
  onAddTag: (label: string) => void;
  onRemoveTag: (tag: Tag) => void;
  selectedTags: Tag[];
  allowTagCreation?: boolean;
}

// Disallow comma character "," on tag labels
const VALID_INPUT_PATTERN = /^[^,]*$/;

export const Tags = (props: TagsProps) => {
  const { onAddTag, onRemoveTag, selectedTags, allowTagCreation } = props;
  const { t } = useTranslation();
  const [isAddingTag, setIsAddingTag] = useState(false);
  const [resultsMenuOpen, setResultsMenuOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);

  const {
    data: channelTags,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useFetchChannelTags();

  const existingChannelTags =
    channelTags?.pages.flatMap((page) => page.results) || [];

  const filteredChannelTags = existingChannelTags.filter((tag) =>
    tag.content.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const focusTextInput = () => {
    // Ensures focus after popover trigger
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const handleOpenChange = (open: boolean) => {
    setResultsMenuOpen((prev) => !prev);
    if (open && inputRef.current) {
      focusTextInput();
    }
  };

  useOnClickOutside(containerRef, () => setResultsMenuOpen(false));

  const clearSearch = () => setSearchTerm('');

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      if (!searchTerm.trim()) {
        return;
      }

      const tagExistInChannel = existingChannelTags.some(
        (tag) =>
          tag.content.trim().toLowerCase() === searchTerm.trim().toLowerCase()
      );

      if (tagExistInChannel || allowTagCreation) {
        onAddTag(searchTerm);
        setResultsMenuOpen(false);
        clearSearch();
      }
    }
  };

  useEffect(() => {
    if (searchTerm.length > 0) {
      setResultsMenuOpen(true);
      focusTextInput();
    } else {
      setResultsMenuOpen(false);
    }
  }, [searchTerm]);

  const handleAddTag = (content: string) => {
    setResultsMenuOpen(false);
    onAddTag(content);
  };

  const handleRemoveTag = (tag: Tag) => {
    onRemoveTag(tag);
  };

  const handleAddClick = () => {
    setIsAddingTag(true);
    focusTextInput();
  };

  const handleInputChange = (value: string) => {
    if (!VALID_INPUT_PATTERN.test(value)) {
      return;
    }
    setSearchTerm(value);
  };

  return (
    <div className={Styles.tagsContainer}>
      <div className={Styles.tagsInsert}>
        {!isAddingTag ? (
          <Button
            fill="ghost"
            variant="subtle"
            size="xs"
            className={Styles.addButton}
            onClick={handleAddClick}
          >
            <Icons.AddIcon aria-hidden />
            {t('Add')}
          </Button>
        ) : (
          <Popover.Root open={resultsMenuOpen} onOpenChange={handleOpenChange}>
            <Popover.Trigger asChild className={Styles.trigger}>
              <TextInput.Root variant="dark" fill="sheer" size="sm">
                <TextInput.Input
                  placeholder={t('Enter tag')}
                  value={searchTerm}
                  onKeyDown={handleKeyDown}
                  ref={inputRef}
                  maxLength={TAGS_LABEL_MAX_LENGTH}
                  onChange={handleInputChange}
                >
                  {searchTerm ? (
                    <TextInput.Adornment align="end">
                      <IconButton
                        fill="none"
                        icon={<Icons.CloseIcon aria-hidden />}
                        iconSize="14"
                        label={t('Clear')}
                        size="xs"
                        variant="subtle"
                        onClick={clearSearch}
                      />
                    </TextInput.Adornment>
                  ) : null}
                </TextInput.Input>
              </TextInput.Root>
            </Popover.Trigger>
            <Popover.Portal>
              <Popover.Content
                align="center"
                sideOffset={8}
                className={Styles.resultsContainer}
                ref={containerRef}
              >
                <Popover.Close />
                {filteredChannelTags.length > 0 ? (
                  <div ref={panelRef} className={Styles.results}>
                    {filteredChannelTags.map((existingTag) => {
                      const isSelected = !!selectedTags?.find(
                        (tag) => tag.id === existingTag.id
                      );
                      return (
                        <TagResult
                          key={existingTag.id}
                          isSelected={isSelected}
                          {...existingTag}
                          onAddTag={() => handleAddTag(existingTag.content)}
                        />
                      );
                    })}
                    {hasNextPage ? (
                      <LoadMoreTags
                        hasNextPage={hasNextPage}
                        fetchNextPage={fetchNextPage}
                        isFetchingNextPage={isFetchingNextPage}
                      />
                    ) : null}
                  </div>
                ) : null}
              </Popover.Content>
            </Popover.Portal>
          </Popover.Root>
        )}
      </div>
      {selectedTags?.length > 0 ? (
        <div className={Styles.tagsList}>
          {selectedTags.map((tag) => (
            <InputChip
              key={tag.id}
              label={tag.content}
              fill="ghost"
              condensed
              onDismiss={() => handleRemoveTag(tag)}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};
