import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js';
import { useProfileQuery } from '@studio/hooks';
import { trackEvent } from '@studio/lib/heap';
import { Toast, Button, Text } from '@lib/ui';
import { debounce } from '@lib/utils';
import * as EVENTS from './../heap.constants';
import {
  useCreateStripeCheckoutSession,
  useCreateStripeCustomer,
} from './../hooks';
import { PlanType, Plan } from './../types';
import * as Styles from './fixed.cta.css';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY || '');

interface FixedCTAProps {
  selectedPlan?: PlanType;
  plan: Plan;
}

export function FixedCTA({ selectedPlan, plan }: FixedCTAProps) {
  const { t } = useTranslation();
  const { toast } = Toast.useToast();
  const { data, error: profileError } = useProfileQuery();
  const { createCustomer } = useCreateStripeCustomer();
  const { createSession } = useCreateStripeCheckoutSession();
  const [isProcessing, setIsProcessing] = useState(false);

  console.warn('plan', plan.trial.period);

  if (profileError || !data) {
    console.error('Unable to retrieve profile data. Cannot create customer.');
  }

  const handleCreateCustomerAndSession = async (planType: PlanType) => {
    try {
      const primaryOrgUser = data?.organizationUsers.find(
        (orgUser) => orgUser.role === 'primary'
      );

      const orgId = primaryOrgUser?.organizationId;

      if (!orgId) {
        console.error('Organization ID is undefined. Cannot create customer.');
      }

      if (data && orgId) {
        const customer = await createCustomer({
          email: data.email,
          orgId,
          name: `${data.firstName || ''} ${data.lastName || ''}`,
        });

        if (customer) {
          const session = await createSession({
            planType,
            plan,
            customerId: customer.id,
            freeTrialEligible: data.freeTrialEligible,
          });
          return session;
        }
      }
    } catch (error) {
      console.error(
        'An error occurred during customer/session creation:',
        error
      );
      toast({
        message: t('Failed to subscribe. Please try again later.'),
      });
    }
  };

  const handleRedirect = debounce(
    async (planType: PlanType) => {
      if (isProcessing) return;

      setIsProcessing(true);

      const stripe = await stripePromise;

      try {
        const session = await handleCreateCustomerAndSession(planType);

        if (stripe && session) {
          trackEvent(EVENTS.SUBSCRIPTION_SIGNUP_START_TRIAL_CLICK);
          await stripe.redirectToCheckout({
            sessionId: session.sessionId,
          });
        }
      } catch (error) {
        console.error('An error occurred during the checkout process:', error);
        toast({
          message: t('Failed to subscribe. Please try again later.'),
        });
      } finally {
        setIsProcessing(false);
      }
    },
    1000,
    true
  );

  return (
    <div className={Styles.fixedCTAContainer}>
      <div className={Styles.fixedCTA}>
        <Button
          onClick={() => {
            if (selectedPlan) {
              handleRedirect(selectedPlan);
            }
          }}
          fullWidth
          radii="pill"
          className={Styles.button}
          size="lg"
          variant="primary"
        >
          <Trans t={t}>
            <Text size="16">
              {plan && plan.trial.period
                ? `Try free for ${plan.trial.period} `
                : 'Subscribe'}
              days
            </Text>
          </Trans>
        </Button>
        <Text align="center">{t('No commitment. Cancel any time.')}</Text>
      </div>
    </div>
  );
}
