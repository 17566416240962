import type { ReactElement, ReactNode } from 'react';
import { format, subMonths } from 'date-fns';
import {
  OutliersFilterInput,
  VideoDurationFilterInput,
  VideoViewsFilterInput,
} from '@studio/components/filters/inputs';
import { Icons } from '@lib/ui';
import {
  DateRangeFilterInput,
  MadeForKidsFilterToggles,
  SubscribersFilterInput,
} from './components/filters/inputs';
import { FILTER_PARAMS, SOURCE, SORT_ORDER, PARAM_KEYS } from './constants';
import * as EVENTS from './heap.constants';

type FilterParamValues = (typeof FILTER_PARAMS)[keyof typeof FILTER_PARAMS];
type SortParamValues = (typeof SORT_ORDER)[keyof typeof SORT_ORDER];

export const OUTLIERS_FILTERS = {
  SOURCE: 'source',
  OUTLIER_INDEX: 'outlier_index',
  UPLOAD_DATE: 'upload_date',
  SUBSCRIBER_COUNT: 'subscriber_count',
  VIDEO_VIEWS: 'video_views',
  VIDEO_LENGTH: 'video_length',
  MADE_FOR_KIDS: 'made_for_kids',
} as const;

// Note: Order here matters, if you want to re-order these
// in the UI, re-order them here. Top to bottom = left to right.
export const oldFilterPresets: FilterPreset[] = [
  {
    id: 'audience_also_watched',
    label: 'Audience also watched',
    icon: <Icons.TeamIcon aria-hidden />,
    filters: [
      OUTLIERS_FILTERS.SOURCE,
      OUTLIERS_FILTERS.OUTLIER_INDEX,
      OUTLIERS_FILTERS.UPLOAD_DATE,
    ],
    params: {
      [FILTER_PARAMS.SOURCE]: SOURCE.AAW,
      [FILTER_PARAMS.PERFORMANCE_INDEX_GTE]: '2',
      [FILTER_PARAMS.UPLOADED_START]: format(
        subMonths(new Date(), 3),
        'MM/dd/yyyy'
      ),
    },
    sort: SORT_ORDER.UPLOADED_DESC,
  },
  {
    id: 'newest_outliers',
    label: 'Newest outliers',
    icon: <Icons.SunIcon aria-hidden />,
    filters: [
      OUTLIERS_FILTERS.SOURCE,
      OUTLIERS_FILTERS.OUTLIER_INDEX,
      OUTLIERS_FILTERS.UPLOAD_DATE,
    ],
    params: {
      [FILTER_PARAMS.SOURCE]: SOURCE.ALL,
      [FILTER_PARAMS.PERFORMANCE_INDEX_GTE]: '5',
      [FILTER_PARAMS.UPLOADED_START]: format(
        subMonths(new Date(), 3),
        'MM/dd/yyyy'
      ),
    },
    sort: SORT_ORDER.UPLOADED_DESC,
  },
  {
    id: 'followed_creators',
    label: 'Followed creators',
    icon: <Icons.FavoriteOnIcon aria-hidden />,
    filters: [OUTLIERS_FILTERS.SOURCE, OUTLIERS_FILTERS.OUTLIER_INDEX],
    params: {
      [FILTER_PARAMS.SOURCE]: SOURCE.FAVORITECHANNELS,
      [FILTER_PARAMS.PERFORMANCE_INDEX_GTE]: '2',
    },
    sort: SORT_ORDER.UPLOADED_DESC,
  },
  {
    id: 'my_videos',
    label: 'My videos',
    icon: <Icons.PersonIcon aria-hidden />,
    filters: [OUTLIERS_FILTERS.SOURCE],
    params: {
      [FILTER_PARAMS.SOURCE]: SOURCE.MYVIDS,
    },
    sort: SORT_ORDER.UPLOADED_DESC,
  },
];

export const filterPresets: FilterPreset[] = [
  {
    id: SOURCE.AAW,
    label: 'Audience also watched',
    icon: <Icons.TeamIcon aria-hidden />,
    filters: [
      OUTLIERS_FILTERS.SOURCE,
      OUTLIERS_FILTERS.OUTLIER_INDEX,
      OUTLIERS_FILTERS.UPLOAD_DATE,
      OUTLIERS_FILTERS.MADE_FOR_KIDS,
    ],
    params: {
      [FILTER_PARAMS.SOURCE]: SOURCE.AAW,
      [FILTER_PARAMS.PERFORMANCE_INDEX_GTE]: '2',
      [FILTER_PARAMS.UPLOADED_START]: format(
        subMonths(new Date(), 3),
        'MM/dd/yyyy'
      ),
      [FILTER_PARAMS.MADE_FOR_KIDS]: 'false',
    },
    sort: SORT_ORDER.UPLOADED_DESC,
  },
  {
    id: SOURCE.ALL,
    label: 'Newest outliers',
    icon: <Icons.SunIcon aria-hidden />,
    filters: [
      OUTLIERS_FILTERS.SOURCE,
      OUTLIERS_FILTERS.OUTLIER_INDEX,
      OUTLIERS_FILTERS.UPLOAD_DATE,
      OUTLIERS_FILTERS.MADE_FOR_KIDS,
    ],
    params: {
      [FILTER_PARAMS.SOURCE]: SOURCE.ALL,
      [FILTER_PARAMS.PERFORMANCE_INDEX_GTE]: '5',
      [FILTER_PARAMS.UPLOADED_START]: format(
        subMonths(new Date(), 3),
        'MM/dd/yyyy'
      ),
      [FILTER_PARAMS.MADE_FOR_KIDS]: 'false',
    },
    sort: SORT_ORDER.UPLOADED_DESC,
  },
  {
    id: SOURCE.FAVORITECHANNELS,
    label: 'Followed creators',
    icon: <Icons.FavoriteOnIcon aria-hidden />,
    filters: [
      OUTLIERS_FILTERS.SOURCE,
      OUTLIERS_FILTERS.OUTLIER_INDEX,
      OUTLIERS_FILTERS.MADE_FOR_KIDS,
    ],
    params: {
      [FILTER_PARAMS.SOURCE]: SOURCE.FAVORITECHANNELS,
      [FILTER_PARAMS.PERFORMANCE_INDEX_GTE]: '2',
      [FILTER_PARAMS.MADE_FOR_KIDS]: 'false',
    },
    sort: SORT_ORDER.UPLOADED_DESC,
  },
  {
    id: SOURCE.MYVIDS,
    label: 'My videos',
    icon: <Icons.PersonIcon aria-hidden />,
    filters: [OUTLIERS_FILTERS.SOURCE, OUTLIERS_FILTERS.MADE_FOR_KIDS],
    params: {
      [FILTER_PARAMS.SOURCE]: SOURCE.MYVIDS,
      [FILTER_PARAMS.MADE_FOR_KIDS]: 'false',
    },
    sort: SORT_ORDER.PERFORMANCE_INDEX_DESC,
  },
];

export type FilterPreset = {
  /**
   * Unique id per filter preset, can be any string.
   */
  id: string;
  /**
   * Human readable label for each filter preset
   */
  label: string;
  /**
   * Controls which filter in the accordion dropdown should be toggled ON when
   * a given filter preset is selected.
   */
  filters: (typeof OUTLIERS_FILTERS)[keyof typeof OUTLIERS_FILTERS][];
  /**
   * Icon to display in the filter preset chip
   */
  icon: ReactElement;
  /**
   * Set the params to filter Outliers by
   */
  params: Partial<Record<FilterParamValues, string>>;
  /**
   * How to sort the given filter preset's results
   */
  sort: SortParamValues;
};

export type OutliersMenuFilterData = {
  label: string;
  input: ReactNode;
  params: string[];
  value: string;
};

export const filters: OutliersMenuFilterData[] = [
  {
    label: 'Outlier index',
    value: OUTLIERS_FILTERS.OUTLIER_INDEX,
    input: (
      <OutliersFilterInput
        minId={PARAM_KEYS.PERFORMANCE_INDEX_GTE}
        maxId={PARAM_KEYS.PERFORMANCE_INDEX_LTE}
        eventNames={{
          opened: EVENTS.OUTLIERS_FILTER_INDEX_OPEN,
          changed: EVENTS.OUTLIERS_FILTER_INDEX_CHANGE,
        }}
      />
    ),
    params: [
      FILTER_PARAMS.PERFORMANCE_INDEX_GTE,
      FILTER_PARAMS.PERFORMANCE_INDEX_LTE,
    ],
  },
  {
    label: 'Upload date',
    value: OUTLIERS_FILTERS.UPLOAD_DATE,
    input: <DateRangeFilterInput />,
    params: [FILTER_PARAMS.UPLOADED_END, FILTER_PARAMS.UPLOADED_START],
  },
  {
    label: 'Subscriber count',
    value: OUTLIERS_FILTERS.SUBSCRIBER_COUNT,
    input: <SubscribersFilterInput />,
    params: [FILTER_PARAMS.SUBSCRIBERS_GTE, FILTER_PARAMS.SUBSCRIBERS_LTE],
  },
  {
    label: 'Video views',
    value: OUTLIERS_FILTERS.VIDEO_VIEWS,
    input: (
      <VideoViewsFilterInput
        minId={PARAM_KEYS.VIEWS_GTE}
        maxId={PARAM_KEYS.VIEWS_LTE}
        onChangeEventName={EVENTS.OUTLIERS_FILTER_VIDEO_VIEWS_CHANGE}
      />
    ),
    params: [FILTER_PARAMS.VIEWS_GTE, FILTER_PARAMS.VIEWS_LTE],
  },
  {
    label: 'Video length',
    value: OUTLIERS_FILTERS.VIDEO_LENGTH,
    input: (
      <VideoDurationFilterInput
        minId={PARAM_KEYS.DURATION_GTE}
        maxId={PARAM_KEYS.DURATION_LTE}
        onChangeEventName={EVENTS.OUTLIERS_FILTER_VIDEO_DURATION_CHANGE}
      />
    ),
    params: [FILTER_PARAMS.DURATION_GTE, FILTER_PARAMS.DURATION_LTE],
  },
  {
    label: 'Made for kids',
    value: OUTLIERS_FILTERS.MADE_FOR_KIDS,
    input: <MadeForKidsFilterToggles />,
    params: [FILTER_PARAMS.MADE_FOR_KIDS],
  },
];
