import { api } from '@studio/lib';
import { ChannelTagsAPIResponse } from '../types';

export const fetchChannelTags = async (
  page: number,
  orgId: string,
  channelUcid: string
): Promise<ChannelTagsAPIResponse> => {
  const params = new URLSearchParams({
    page: page.toString(),
    pageSize: '1000', // Temporary, to allow FE typehead. Remove once Autocomple is ready.
  });
  const response = await api.bowser.get<ChannelTagsAPIResponse>(
    `/api/orgs/${orgId}/channels/${channelUcid}/tags?${params}`
  );

  return response;
};
