export const freeformIdeationRoutes = [
  {
    path: ':organizationId/:channelUcid/freeform-ideation',
    async lazy() {
      const { FreeformIdeationRoute } = await import('./freeform-ideation');
      return {
        Component: FreeformIdeationRoute,
      };
    },
  },
];
